
:root {
  --accent-neg-2: #176922;
  --accent-neg-1: #6BFF7F;
  --primary: #3ab64a;
  --accent-1: #690D25;
  --accent-2: #B53A5A;
}


body {
	font-family: 'Unbounded', Verdana, Geneva, Tahoma, sans-serif;
}

.page {
	margin-top: var(--navbar-height, 0);
	min-height: calc(var(--window-height) - var(--navbar-height, 0) - var(--footer-height, 0));
	position: relative;
}

.page > .page-size {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.fill-container, .fill-parent {
	height: 100%;
	width: 100%;
}

.truncate-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.vh-align, .vhalign {
	display: flex;
	align-items: center;
	justify-content: center;
}

.v-align, .valign {
	display: flex;
	align-items: center;
}

.h-align, .halign {
	display: flex;
	justify-content: center;
}

.center-align {
	text-align: center;
}

.right-align {
	text-align: right;
}

.grey-text {
	color: gray;
}

.white-text {
	color: white;
}

.primary-text {
	color: var(--primary);
}

.red-bg {
	background-color: red;
}

.white-bg {
	background-color: white;
}

.primary-bg {
	background-color: var(--primary);
}

h1, h2, h3, h4, h5, h6 {
	color: var(--primary);
}

.mui-table thead th {
	font-weight: bold !important;
}

.font-30 {
	font-size: 30px;
}

.margin-20 {
	margin: 20px;
}

.form > * {
	margin: 10px auto !important;
}

.MuiButton-root {
	z-index: 0 !important;
}