
@keyframes stretch {
   0% {
      transform: scale(.98);
   }

   100% {
      transform: scale(1.02);
   }
}


.pulse {
   animation-name: stretch;
   animation-duration: 0.45s;
   animation-timing-function: ease-out;
   animation-direction: alternate;
   animation-iteration-count: infinite;
   animation-play-state: running;
}